import React from 'react';
import {Link} from 'react-router-dom';
import '../assets/css/styles.css';
import thecodes_white from "../thecodes_white.png";
import Axios from 'axios';
import { apiURL, prefixDefault, prefixValidator, ibcEnable } from '../data/API';
import {getPathName} from '../redux/actions/getData';
import { connect } from 'react-redux';

class Header extends React.Component {
  state = {}
  componentWillMount() {
    this.props.getPathName(window.location.pathname);
  }
  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }
  searchHandle(searchString){
    if(searchString){
      if(Number.isInteger(parseInt(searchString)) && searchString.length < 44){
        window.location.replace(`/block/${parseInt(searchString)}`);
      }else if(searchString.length === prefixDefault.length+39 && searchString.includes(prefixDefault)){
        window.location.replace(`/address/${searchString}`);
      }else if(searchString.length === prefixValidator.length+39 && searchString.includes(prefixValidator)){
        window.location.replace(`/validator/${searchString}`);
      }else if(searchString.length === 64){
        Axios.get(`${apiURL}/search?hash=${searchString}`)
        .then(getType => {
          if(getType.data.result){
            if(getType.data.data === 'transaction'){
              window.location.replace(`/tx/${searchString}`);
            }else if(Number.isInteger(getType.data.data)){
              window.location.replace(`/block/${getType.data.data}`);
            }else if(getType.data.data === 'ibc'){
              window.location.replace(`/ibc/${searchString}`);
            }
          }
        })
      }
    }
  }
  render(){
    return(
      <div className="header-earlier">
        <div className="header-div">
          <div className="header shadow">
            <div className="header-left">
              <div className="network-using">
                <img src="https://foundation.ki/static/media/ki_foundation_mobile.2f41efa2.png" alt="KiChain Explorer"/>
              </div>
              <Link to="/" onClick={() => this.changePath("/")}><img src={thecodes_white} alt="TheCodes Explorer" className="thecodes-header" /></Link>
              <div className="header-menu-show">
                <i className="bi bi-text-left"></i>
              </div>
              <div className="header-right">
                <div className="search-header">
                  <input type="text" placeholder="Search by block hash, tx hash, address, or IBC hash" value={this.state.searchString} onKeyDown={(e) => e.key === "Enter" ? this.searchHandle(this.state.searchString) : null} onChange={e => this.setState({searchString: e.target.value})} />
                  <button onClick={() => this.searchHandle(this.state.searchString)} type="button"><i className="icon bi-search"></i></button>
                </div>
                <Link className="header-link" to="/validators" onClick={() => this.changePath("/validators")}>
                  <div className="header-menu" style={this.props.pathName === "/validators" ? {color: "yellow"} : {color: "white"}}>
                    Validators
                  </div>
                </Link>
                {
                  ibcEnable ?
                  <>
                    <Link className="header-link" to="/ibcs" onClick={() => this.changePath("/ibcs")}>
                      <div className="header-menu" style={this.props.pathName === "/ibcs" ? {color: "yellow"} : {color: "white"}}>
                        IBC
                      </div>
                    </Link>
                    <Link className="header-link" to="/tokens" onClick={() => this.changePath("/tokens")}>
                      <div className="header-menu" style={this.props.pathName === "/tokens" ? {color: "yellow"} : {color: "white"}}>
                        Tokens
                      </div>
                    </Link>
                  </>
                  : null
                }
              </div>
            </div>
            <div className="header-search">
              <input type="text" placeholder="Search by block hash, tx hash, address, or IBC token hash" value={this.state.searchString} onKeyDown={(e) => e.key === "Enter" ? this.searchHandle(this.state.searchString) : null} onChange={e => this.setState({searchString: e.target.value})} />
              <button onClick={() => this.searchHandle(this.state.searchString)} type="button"><i className="icon bi-search"></i></button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pathName: state.user.pathName
  }
};

const mapDispatchToProps = {
  getPathName,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);