import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from './components/Header';
import Home from './pages/Home';
import Block from './pages/Block';
import Transaction from './pages/Transaction';
import Validator from './pages/Validator';
import Account from './pages/Account';
import IBC from './pages/IBC';
import IBCS from './pages/IBCS';
import Validators from './pages/Validators';
import Tokens from './pages/Tokens';
import Token from './pages/Token';
import Footer from './components/Footer';

function App() {
  return (
    
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact component={IBCS} path="/ibcs"/>
        <Route exact component={Tokens} path="/tokens"/>
        <Route exact component={Token} path="/token/:denom"/>
        <Route exact component={Validators} path="/validators/inactive"/>
        <Route exact component={Validators} path="/validators/active"/>
        <Route exact component={Validators} path="/validators"/>
        <Route exact component={Block} path="/block/:height"/>
        <Route exact component={Validator} path="/validator/:address"/>
        <Route exact component={Transaction} path="/tx/:hash"/>
        <Route exact component={Account} path="/address/:address"/>
        <Route exact component={IBC} path="/ibc/:hash"/>
        <Route exact component={Home} path="/"/>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
