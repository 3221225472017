import React from 'react';
import thecodes_black from "../thecodes_black.png";

class Footer extends React.Component {
  render(){
    return (
      <div className="footer-container">
        <hr/>
        <div className="row">
          <div className="col-md-4">
            <div onClick={() => window.scrollTo(0, 0)} style={{height:"60px", width:"130px", backgroundImage: `url(${thecodes_black})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}></div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-5">
                <div className="footer-social">
                  <a href="https://twitter.com/thecodesdev" rel="noreferrer" target="_blank"><i className="bi bi-twitter"></i></a>
                  <a href="https://facebook.com/groups/Pemburu.Bitcoin.Indonesia/" rel="noreferrer" target="_blank"><i className="bi bi-facebook"></i></a>
                  <a href="https://t.me/thecodescommunity" rel="noreferrer" target="_blank"><i className="bi bi-telegram"></i></a>
                </div>
              </div>
              <div className="col-7">
                <div className="footer-copyright">
                  <p>&copy; 2021 <a href="https://thecodes.dev" rel="noreferrer" target="_blank">TheCodes</a> <i className="bi bi-heart-fill text-danger"></i></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;