import React from 'react';
import '../assets/css/styles.css';
import { apiURL, denomination, projectName } from '../data/API';
import Axios from 'axios';
import Tooltip from "@material-ui/core/Tooltip";
import Badge from '@material-ui/core/Badge';
import person from '../person.svg';
import TransactionDetail from '../components/TransactionDetail';
import {Link} from 'react-router-dom';
import Label from '../components/Label';
import { connect } from 'react-redux';
import { getPrice } from '../redux/actions/getData';

class Validator extends React.Component {
  state = {}
  componentDidMount() {
    this.getData();
    setInterval(() => {
      if(this.state.statusCommit){
        this.setState({statusCommit: ''});
        this.getCommit();
      }
    }, 6000);
    document.title = `Validator ${this.props.match.params.address} | ${projectName} Scan`;
  }
  componentWillUpdate(){
    if(this.state.address !== this.props.match.params.address){
      this.getData()
    }
  }
  getData = async() => {
    Axios.get(`${apiURL}/validator?address=${this.props.match.params.address}`)
    .then((response) => {
      if(response.data.result){
        this.setState({
          checkDetails: true,
          address: response.data.data.address,
          block: response.data.data.block,
          operatorAddress: response.data.data.operatorAddress,
          key: response.data.data.key,
          moniker: response.data.data.info.moniker,
          identity: response.data.data.info.identity,
          details: response.data.data.info.details,
          website: response.data.data.info.website,
          status: response.data.data.statusDetails.status,
          delegated: response.data.data.delegated,
          self: response.data.data.selfDelegated,
          owner: response.data.data.selfDelegatedAddress,
          fee: response.data.data.feeDetails.fees,
          maxFees: response.data.data.feeDetails.maxFees,
          imageUrl: response.data.data.imageUrl ? response.data.data.imageUrl : person,
          keybase: response.data.data.keybase,
          delegator: response.data.data.delegator
        })
      }else{
        this.setState({
          error: "Unable to fetch validator!"
        })
      }
    }).catch((err)=>{
      this.setState({
        error: "Unable to fetch validator!"
      })
    })
    this.getCommit();
    Axios.get(`${apiURL}/txs?address=${this.props.match.params.address}`)
    .then((response) => {
      if(response.data.result){
        this.setState({
          checkTx: true,
          txs: (response.data.data).reverse(),
          checkTxs: true
        })
      }else{
        this.setState({
          error: "Unable to fetch transaction!"
        })
      }
    })
    .catch(err => {
      this.setState({
        error: "Unable to fetch transaction!"
      })
    })
  }
  getCommit(){
    Axios.get(`${apiURL}/commits?address=${this.props.match.params.address}`)
    .then((response) => {
      if(response.data.result){
        this.setState({
          checkCommit: true,
          block: response.data.data.block,
          commitDetails: response.data.data.commited,
          statusCommit: response.status
        })
      }else{
        this.setState({
          error: "Unable to fetch uptime!",
          statusCommit: response.status
        })
      }
    })
  }
  renderUptime(){
    if(this.state.commitDetails){
      var uptime = []
      for(var i = parseInt(this.state.block)-1; i > parseInt(this.state.block)-500; i--){
        var color = "rgba(0,0,0,0.2)";
        if((this.state.commitDetails).indexOf(i) > -1){
          color = "green";
        }
        uptime.push(
          <Tooltip interactive title={i} placement="top-start"><div className="uptime-light" style={{"backgroundColor": color}}></div></Tooltip>
        )
      }
      return uptime
    }
  }
  renderDelegator(){
    if(this.state.delegator){
      (this.state.delegator).sort(function(a, b) {
        return a.amount - b.amount
      });
      (this.state.delegator).reverse();
      var delegator = (this.state.delegator).map(res => {
        return(
          <div>
            <div className="row">
              <div className="col-5">
                <p className="text-hash"><Link to={`/address/${res.delegatorAddress}`}>{res.delegatorAddress}</Link></p>
              </div>
              <div className="col-7">
                <p><b>{(parseInt(res.amount)/10**12).toLocaleString(undefined, {maximumFractionDigits: 5})}</b> {denomination}</p>
              </div>
            </div>
            <hr style={{"marginTop": "5px"}}/>
          </div>
        )
      })
      return delegator;
    }
  }

  render() {
    return(
      <div className="summary-div">
        {
          this.state.checkDetails && this.state.address === this.props.match.params.address ?
            <>
            <div className="summary shadow" style={{padding: "12px"}}>
              <div className="row">
                <div className="col-md-1 col-3" style={{"textAlign": "center"}}>
                  <img src={this.state.imageUrl} alt={this.state.moniker} style={{"border-radius": "5px", "height": "60px", "width": "auto"}} />
                </div>
                <div className="col-md-11 col-9">
                  <p className="content-table-text" style={{"marginBottom": "0"}}><b>{this.state.moniker} </b> 
                  {
                    this.state.website ?
                      <a href={(this.state.website).includes("https://") || (this.state.website).includes("http://") ? this.state.website : `https://${this.state.website}`} target="_blank" rel="noreferrer"><i className="bi bi-globe2"></i> </a>
                    : null
                  }
                  {
                    this.state.status === "active" ?
                      <Badge color="primary" badgeContent="Active" style={{"marginLeft": "25px"}} />
                    : this.state.status === "inactive" ?
                      <Badge color="default" badgeContent="Inactive" style={{"marginLeft": "25px"}}/>
                    : <Badge color="secondary" badgeContent="Jailed" style={{"marginLeft": "25px"}}/>
                  }</p>
                  <p className="content-table-link" style={{"marginBottom": "0"}}><Tooltip interactive title={this.state.keybase} placement="top-start"><a href={`https://keybase.io/${this.state.keybase}`} target="_blank" rel="noreferrer">{this.state.identity}</a></Tooltip></p>
                  <p className="content-table-text" style={{"marginBottom": "-0"}}>{this.state.details}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div className="summary shadow" style={{padding: "12px"}}>
                  <strong style={{fontSize: "17px", marginLeft: "0"}}>Delegation Details</strong>
                  <hr/>
                  <div ref={element => this.detailHeight = element}>
                    <Label validator label="Operator" value={this.state.operatorAddress} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.operatorAddress)}}></i>} tooltip />
                    <Label validator label="Owner" value={this.state.owner} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.owner)}}></i>} linkTo={`/address/${this.state.owner}`} link tooltip />
                    <Label validator label="Delegation" value={(parseInt(this.state.delegated)/10**12).toLocaleString(undefined, {maximumFractionDigits: 5})} denom />
                    <Label validator label="Self-Delegation" value={(parseInt(this.state.self)/10**12).toLocaleString(undefined, {maximumFractionDigits: 5})} percentageValue={`${((parseInt(this.state.self)/parseInt(this.state.delegated))*100).toFixed(2)}%`} percentage denom />
                    <Label validator label="Commission" value={`${(parseInt(this.state.fee)).toFixed(2)}%`} />
                    <Label validator label="Max. Commission" value={`${(parseInt(this.state.maxFees)).toFixed(2)}%`} />
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                {
                  this.detailHeight ?
                    <div className="summary shadow overflow-custom" style={{"padding": "12px"}}>
                      <strong style={{fontSize: "17px", marginLeft: "0"}}>Delegators  <b style={{"opacity": "0.75", "fontWeight": "normal", "fontSize": "13px"}}>{((this.state.delegator).length)}</b></strong>
                      <hr/>
                      <div className="overflow-custom" style={{"height": this.detailHeight.clientHeight+13, "overflowY": "auto", "overflowX": "hidden"}}>
                        {this.renderDelegator()}
                      </div>
                    </div>
                  :
                    <div className="summary shadow" style={{"textAlign": "center", "padding": "12px", "height": "287px"}}>
                      <div style={{margin: "0 auto", marginTop: "103px"}}>
                        <div class="o1"></div>
                        <div class="o2"></div>
                        <div class="o3"></div>
                      </div>
                    </div>
                }
              </div>
            </div>
            {
              this.state.checkCommit ?
                <div className="summary shadow" style={{padding: "12px"}}>
                  <strong style={{fontSize: "17px", marginLeft: "0"}}>Commits <b style={{"opacity": "0.75", "fontWeight": "normal", "fontSize": "13px"}}>{((this.state.commitDetails).length/500*100).toFixed(2)}%</b></strong>
                  <hr/>
                  <div className="uptime-container">
                    {this.renderUptime()}
                  </div>
                </div>
              :
              <div className="summary shadow" style={{"textAlign": "center", "padding": "12px", "height": "200px"}}>
                <div style={{margin: "0 auto", marginTop: "60px"}}>
                  <div class="o1"></div>
                  <div class="o2"></div>
                  <div class="o3"></div>
                </div>
              </div>
            }
            {
              this.state.checkTxs ?
                <div className="summary shadow overflow-custom" style={{"padding":"12px"}}>
                  <strong style={{fontSize: "17px", marginLeft: "0"}}>Transaction</strong>
                  <hr/>
                  <div className="overflow-custom" style={{"maxHeight": "400px", "overflowY": "auto", "overflowX": "hidden"}}>
                    <TransactionDetail jsonTx={this.state.txs} address={this.props.match.params.address}/>
                  </div>
                </div>
              :
              <div className="summary shadow" style={{"textAlign": "center", "height": "300px"}}>
                <div style={{margin: "0 auto", marginTop: "125px"}}>
                  <div class="o1"></div>
                  <div class="o2"></div>
                  <div class="o3"></div>
                </div>
              </div>
            }
            </>
          :
          <div className="summary shadow" style={{"textAlign": "center", "height": "622px"}}>
            <div style={{margin: "0 auto", marginTop: "275px"}}>
              <div class="o1"></div>
              <div class="o2"></div>
              <div class="o3"></div>
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};

const mapDispatchToProps = {
  getPrice
}

export default connect(mapStateToProps, mapDispatchToProps)(Validator);