import React from 'react';
import { connect } from 'react-redux';
import {getValidators} from '../redux/actions/getData';
import {denomination} from '../data/API';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import person from '../person.svg';
import Badge from '@material-ui/core/Badge';

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}
class Validator extends React.Component {
  state = {
    page : "active"
  }
  getImage = () => {
    if((this.props.activeValidators).length > 0 && this.props.imageCheck === false) {
      (this.props.activeValidators).forEach((validator) => {
        this.props.getValidatorImage(validator)
      })
    }
  }
  loopData = (numData) => {
    const columns = [
      {
        field: 'id',
        headerName: '#',
        type: 'number',
        numeric: true,
        headerAlign: 'center',
        sortable: true,
        width: 50,
      },
      {
        field: 'validatorName',
        headerName: 'Name',
        headerAlign: 'center',
        width: 400,
        sortable: true,
        renderCell: (params) => (
          <a href={`/validator/${(params.value).split('|||')[2]}`} title={(params.value).split('|||')[2]}><img className="image-validator" src={(params.value).split('|||')[1]} alt={params.value.identity}/><b className="title-underline">{(params.value).split('|||')[0]}</b></a>
        )
      },
      {
        field: 'delegated',
        headerName: 'Delegated',
        headerAlign: 'center',
        width: 200,
        sortable: true,
        renderCell: (params) => (
          <span><b className="title-underline">{parseInt(params.value/10**12).toLocaleString(undefined, {maximumFractionDigits: 2})}</b> {denomination} (<b>{parseInt((params.value/this.props.bondedCoins)*100).toLocaleString(2)}</b>%)</span>
        )
      },
      {
        field: 'commission',
        headerName: 'Commission',
        headerAlign: 'center',
        numeric: true,
        width: 200,
        sortable: true,
        renderCell: (params) => (
          <span><b className="title-underline">{(params.value).toLocaleString(undefined,{maximumFractionDigits: 2})}</b>%</span>
        )
      },
      { 
        field: 'uptime',
        headerName: 'Uptime',
        headerAlign: 'center',
        numeric: true,
        width: 150,
        sortable: true,
        renderCell: (params) => (
          <span><b className="title-underline">{(params.value/500*100).toLocaleString(undefined, {maximumFractionDigits: 2})}</b>%</span>
        )
      },
      { 
        field: 'statusDetails',
        headerName: 'Status',
        headerAlign: 'center',
        width: 150,
        sortable: true,
        renderCell: (params) => (
          <Badge color={(params.value).split('|||')[1]} badgeContent={(params.value).split('|||')[0]} style={{"margin": "0 auto", "textTransform": "capitalize"}} />
        )
      }
    ];
    if(numData === "all"){
      numData = 125;
    }
    const rows = [];
    var totalData = 0;
    var loading = true;
    if((this.props.activeValidators).length > 0 && this.props.status === "active"){
      loading = false;
      (this.props.activeValidators).forEach((validator) => {
        var imageUrl = person;
        if(validator.imageUrl){
          imageUrl = validator.imageUrl;
        }
        rows.push({
          id: totalData+1,
          validatorName: `${validator.info.moniker}|||${imageUrl}|||${validator.operatorAddress}`,
          delegated: parseInt(validator.delegated),
          commission: validator.feeDetails.fees,
          uptime: validator.totalCommit,
          statusDetails: `${validator.statusDetails.status}|||primary`
        })
        totalData++;
      })
    }else if((this.props.inactiveValidators).length > 0 && this.props.status === "inactive"){
      loading = false;
      totalData = (this.props.activeValidators).length;
      (this.props.inactiveValidators).forEach((validator) => {
        var color = "secondary";
        if(validator.statusDetails.status === "inactive"){
          color = "default"
        }
        var imageUrl = person;
        if(validator.imageUrl){
          imageUrl = validator.imageUrl;
        }
        rows.push({
          id: totalData+1,
          validatorName: `${validator.info.moniker}|||${imageUrl}|||${validator.operatorAddress}`,
          delegated: parseInt(validator.delegated),
          delegatedShares: parseInt(validator.delegated),
          commission: validator.feeDetails.fees,
          uptime: validator.totalCommit,
          statusDetails: `${validator.statusDetails.status}|||${color}`
        })
        totalData++;
      })
    }
    return(
    <DataGrid
      rows={rows} 
      components={{
        LoadingOverlay: CustomLoadingOverlay,
      }}
      columns={columns} 
      autoHeight
      loading={loading}
      hideFooterPagination= {this.props.pagination}
      hideFooterSelectedRowCount
      pageSize={numData}
      style={{border: "none"}}
    />)
  }
  changeStatus = (page) => {
    this.setState({page: page})
  }
  render() {
    return (
      this.loopData(this.props.total)
    )
  }
  componentDidMount(){
    this.getImage()
    setInterval(() => {
      this.getImage();
    }, 1000);
  }
}

const mapStateToProps = (state) => {
  return {
    activeValidators: state.user.activeValidators,
    inactiveValidators: state.user.inactiveValidators,
    bondedCoins: state.user.bondedCoins,
  }
}

const mapDispatchToProps = {
  getValidators,
}

export default connect(mapStateToProps, mapDispatchToProps)(Validator);