import React from 'react';
import { connect } from 'react-redux';
import { apiURL, projectName, denomination} from '../data/API';
import { getPathName } from '../redux/actions/getData';
import Axios from 'axios';
import Summary from '../components/Summary';
import '../assets/css/styles.css';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import {Link} from 'react-router-dom';

class Tokens extends React.Component {
  state = {}
  componentDidMount(){
    Axios.get(`${apiURL}/ibctokens`)
    .then(response => {
      this.setState({
        assets: response.data.data
      })
    })
    Axios.get(`${apiURL}/ibcchannel`)
    .then(response => {
      this.setState({
        channels: response.data.data
      })
    })
    document.title = `IBC Tokens | ${projectName} Scan`;
  }
  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }
  renderAssets(){
    if(this.state.assets && this.state.channels) {
      var assetsList = [];
      var denomList = [];
      (this.state.assets).forEach(denom => {
        if(denomList.indexOf(denom.denom) < 0){
          denomList.push(denom.denom);
        }
      })
      
      denomList.forEach(denom => {
        if(denom !== denomination){
          var channelList = [];
          (this.state.assets).forEach(asset => {
            if(asset.denom === denom){
              (this.state.channels).forEach(channel => {
                if(asset.channel === channel.from){
                  var color;
                  if(channel.status === "STATE_OPEN"){
                    color = "green"
                  }else if(channel.status === "STATE_TRYOPEN"){
                    color = "red"
                  }else{
                    color = "grey"
                  }
                  channelList.push(
                    <Link to={`/token/${asset.udenom}`} onClick={() => this.changePath("/token")}>
                      <div className="connected-div">
                        <div className="connected-text">
                          <p><span className="hide-mobile inline">Channel</span> {(channel.from).split("-")[1]}</p>
                        </div>
                        <div class="connected-line" style={{background: color}}></div>
                        <div className="connected-text">
                          <p><span className="hide-mobile inline">Channel</span> {(channel.to).split("-")[1]}</p>
                        </div>
                      </div>
                    </Link>
                  )
                }
              })
            }
          })
          assetsList.push(
            <Accordion>
              <AccordionSummary
                expandIcon={<i className="bi bi-chevron-down"></i>}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="text-hash"
                style={{textTransform: "uppercase", fontWeight: "bold"}}
              >
                {denom} <b style={{"opacity": "0.75", "fontWeight": "normal", "fontSize": "13px","marginTop":"3px","marginLeft":"5px"}}>{((channelList).length)}</b>
              </AccordionSummary>
              <AccordionDetails style={{display: "block", background:"#f7f7f7"}}>
                {channelList}
              </AccordionDetails>
            </Accordion>
          )
        }
      })
      return assetsList;
    }
  }

  render() {
    return(
      <>
        <Summary/>
        <div className="summary-div">
        {
          this.state.assets && this.state.channels ?
            <div className="summary shadow" style={{padding: "12px"}}>
              {this.renderAssets()}
            </div>
          :
          <div className="summary shadow" style={{"textAlign": "center", "height": "622px"}}>
            <div style={{margin: "0 auto", marginTop: "275px"}}>
              <div class="o1"></div>
              <div class="o2"></div>
              <div class="o3"></div>
            </div>
          </div>
        }
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pathName: state.user.pathName
  }
};

const mapDispatchToProps = {
  getPathName,
}

export default connect(mapStateToProps, mapDispatchToProps)(Tokens);