import React from 'react';
import '../assets/css/styles.css';
import { apiURL, projectName } from '../data/API';
import moment from 'moment'
import Axios from 'axios';
import person from '../person.svg';
import Tooltip from "@material-ui/core/Tooltip";
import TransactionDetail from '../components/TransactionDetail';
import {Link} from 'react-router-dom';
import Label from '../components/Label';

class Block extends React.Component {
  state={}
  componentDidMount(){
    this.getData();
    document.title = `Block #${parseInt(this.props.match.params.height).toLocaleString()} | ${projectName} Scan`;
  }
  componentDidUpdate(){
    if(this.state.height !== this.props.match.params.height) {
      this.getData()
    }
  }
  getData = async() => {
    var response = await Axios.get(`${apiURL}/block?height=${this.props.match.params.height}`);
    this.setState({
      height: response.data.data.blockHeight,
      hash: response.data.data.blockHash,
      time: response.data.data.blockTime,
      previousHash: response.data.data.previousBlockHash,
      proposerMoniker: response.data.data.proposerAddress.moniker,
      proposerAddress: response.data.data.proposerAddress.operatorAddress,
      chainId: response.data.data.chainId,
      txs: response.data.data.txs,
      numTxs: (response.data.data.txs).length,
      validators: response.data.data.validators,
      numValidators: response.data.data.numValidators,
    })
  }
  renderValidator(){
    if(this.state.validators){
      const validator = (this.state.validators).map(validator => {
        var image = person
        if(validator.imageUrl){
          image = validator.imageUrl;
        }
        return(
          <div className="col-6 col-md-3">
            <Link to={`/validator/${validator.operatorAddress}`}>
              <Tooltip interactive title={validator.operatorAddress} placement="top-start">
                <div className="summary" style={{"background": "rgba(0,0,0,0.04)"}}>
                  <p className="text-hash" style={{"marginBottom": "0px"}}><img src={image} alt={validator.operatorAddress} className="image-validator" /> {validator.moniker}</p>
                </div>
              </Tooltip>
            </Link>
          </div>
        )
      })
      return validator;
    }
  }
  render() {
    return(
      <div className="shadow-div" style={{padding: "0 25px"}}>
        {
          this.state.hash && this.state.height === this.props.match.params.height ?
          <>
          <div className="summary shadow" style={{padding: "12px"}}>
            <strong style={{fontSize: "17px", marginLeft: "0"}}>Block Details</strong>
            <hr/>
            <Label label="Height" value={parseInt(this.state.height).toLocaleString()} />
            <Label label="Hash" value={this.state.hash} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.hash)}}></i>} tooltip />
            <Label label="Transaction(s)" value={parseInt(this.state.numTxs).toLocaleString()} />
            <Label label="Time" value={`${moment(this.state.time).format("DD/MM/YY, HH:mm:ss")} (${moment(this.state.time).fromNow()})`} />
            <Label label="Chain ID" value={this.state.chainId} />
            <Label label="Proposer" value={this.state.proposerMoniker} linkTo={`/validator/${this.state.proposerAddress}`} link />
            <Label label="Validators" value={this.state.numValidators} />
            <Label label="Parent Hash" value={this.state.previousHash} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.previousHash)}}></i>} linkTo={`/block/${parseInt(this.state.height)-1}`} link tooltip />
          </div>
          {
            this.state.numTxs > 0 ?
            <>
              <div className="summary shadow" style={{padding: "12px"}}>
                <strong style={{fontSize: "17px", marginLeft: "0"}}>Transactions</strong>
                <hr/>
                <div className="overflow-custom" style={{"maxHeight": "435px", "overflowY": "auto", "overflowX": "hidden", "marginBottom": "10px"}}>
                {
                  this.state.txs ?
                    <TransactionDetail jsonTx={this.state.txs}/>
                  : null
                }
                </div>
              </div>
            </>
            :
            <div></div>
          }
          <div className="summary shadow" style={{padding: "12px"}}>
            <strong style={{fontSize: "17px", marginLeft: "0"}}>Validators</strong>
            <hr/>
            <div className="overflow-custom" style={{"maxHeight": "300px", "overflowY": "auto", "overflowX": "hidden"}}>
              <div className="row">
                {this.renderValidator()}
              </div>
            </div>
          </div>
          </>
          :
          <div className="summary shadow" style={{"textAlign": "center", height: "622px"}}>
            <div style={{margin: "0 auto", marginTop: "275px"}}>
              <div class="o1"></div>
              <div class="o2"></div>
              <div class="o3"></div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default Block;