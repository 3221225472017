import React from 'react';
import '../assets/css/styles.css';
import { apiURL, denomination, projectName } from '../data/API';
import Axios from 'axios';
import QRCode from "react-qr-code";
import Tooltip from "@material-ui/core/Tooltip";
import { PieChart } from 'react-minimal-pie-chart';
import moment from 'moment';
import person from '../person.svg';
import TransactionDetail from '../components/TransactionDetail';
import {Link} from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getPrice } from '../redux/actions/getData';

class Account extends React.Component {
  state={}
  componentWillMount(){
    this.props.getPrice();
    this.getData();
    document.title = `Address ${this.props.match.params.address} | ${projectName} Scan`;
  }
  componentDidUpdate(){
    if(this.state.address !== this.props.match.params.address) {
      this.getData();
    }
  }
  getData = async() => {
    Axios.get(`${apiURL}/balance?address=${this.props.match.params.address}`)
    .then(response => {
      if(response.data.result){
        var balance;
        (response.data.data.balance).forEach(bal => {
          if(bal.denom === denomination.toLocaleLowerCase()){
            balance = bal.amount
          }
        })
        this.setState({
          checkBalance: true,
          address: response.data.data.address,
          token: response.data.data.balance,
          balance: balance ? balance : 0,
          sequence: response.data.data.sequence,
          rewards: response.data.data.rewards,
          delegate: response.data.data.delegate,
          undelegate: response.data.data.undelegate,
        })
      }else{
        console.log("Failed to fetch balance")
      }
    })
    .catch(err => {
      console.log("Failed to fetch balance")
    })
    Axios.get(`${apiURL}/delegations?address=${this.props.match.params.address}`)
    .then(response => {
      if(response.data.result){
        this.setState({
          delegates: response.data.data,
          checkDelegations: true
        })
      }else{
        console.log("Failed to fetch delegation")
      }
    })
    Axios.get(`${apiURL}/undelegations?address=${this.props.match.params.address}`)
    .then(response => {
      if(response.data.result){
        this.setState({
          undelegates: response.data.data,
          checkUndelegations: true
        })
      }else{
        console.log("Failed to fetch undelegation")
      }
    })
    .catch(err => {
      console.log("Failed to fetch transactions")
    })
    Axios.get(`${apiURL}/txs?address=${this.props.match.params.address}`)
    .then(response => {
      if(response.data.result){
        this.setState({
          txs: (response.data.data).reverse(),
          checkTxs: true
        })
      }else{
        console.log("Failed to fetch transaction")
      }
    })
    .catch(err => {
      console.log("Failed to fetch transactions")
    })
  }
  renderDelegate = () => {
    if(this.state.delegates){
      var delegates = [];
      (this.state.delegates).forEach(delegate => {
        var image = person;
        if(delegate.imageUrl){
          image = delegate.imageUrl
        }
        delegates.push(
          <div>
            <p className="text-hash" style={{"marginBottom": "5px"}}><Tooltip interactive title={delegate.operatorAddress} placement="top-start"><Link to={`/validator/${delegate.operatorAddress}`}><img className="image-validator" src={image} alt={delegate.operatorAddress}/>{delegate.moniker}</Link></Tooltip></p>
            <div className="row">
              <div className="col-xl-1 col-4" style={{"marginBottom": "-15px"}}>
                <p>Total</p>
              </div>
              <div className="col-xl-5 col-8" style={{"marginBottom": "-15px", "textAlign": "right"}}>
                <p><b>{(parseInt(delegate.balance)/10**12).toLocaleString(undefined, {maximumFractionDigits: 6})}</b> {denomination}</p>
              </div>
              <div className="col-xl-1 col-4" style={{"marginBottom": "-15px"}}>
                <p>Reward</p>
              </div>
              <div className="col-xl-5 col-8" style={{"marginBottom": "-15px", "textAlign": "right"}}>
                <p><b>{(parseInt(delegate.reward)/10**12).toLocaleString(undefined, {maximumFractionDigits: 6})}</b> {denomination}</p>
              </div>
            </div>
            <hr/>
          </div>
        )
      })
      return delegates
    }
  }
  renderUndelegate = () => {
    if(this.state.undelegates){
      var undelegates = [];
      (this.state.undelegates).forEach(undelegate => {
        var image = person;
        if(undelegate.imageUrl){
          image = undelegate.imageUrl
        }
        undelegates.push(
          <div>
            <p className="text-hash" style={{"marginBottom": "5px"}}><Tooltip interactive title={undelegate.operatorAddress} placement="top-start"><Link to={`/validator/${undelegate.operatorAddress}`}><img className="image-validator" src={image} alt={undelegate.operatorAddress}/>{undelegate.moniker}</Link></Tooltip></p>
            <div className="row">
              <div className="col-xl-1 col-4" style={{"marginBottom": "-15px"}}>
                <p>Total</p>
              </div>
              <div className="col-xl-5 col-8" style={{"marginBottom": "-15px", "textAlign": "right"}}>
                <p><b>{(parseInt(undelegate.balance)/10**12).toLocaleString(undefined, {maximumFractionDigits: 6})}</b> {denomination}</p>
              </div>
              <div className="col-xl-3 col-4" style={{"marginBottom": "-15px"}}>
                <p>Start Height</p>
              </div>
              <div className="col-xl-3 col-8" style={{"marginBottom": "-15px", "textAlign": "right"}}>
                <p>{parseInt(undelegate.startHeight).toLocaleString()}</p>
              </div>
              <div className="col-xl-2 col-4" style={{"marginBottom": "-15px"}}>
                <p>End Time</p>
              </div>
              <div className="col-xl-10 col-8" style={{"marginBottom": "-15px", "textAlign": "right"}}>
                <p>{`${moment(undelegate.endTime).format("DD/MM/YY, HH:mm")} (${moment(undelegate.endTime).fromNow()})`}</p>
              </div>
            </div>
            <hr/>
          </div>
        )
      })
      return undelegates
    }
  }

  render(){
    return(
      <div className="summary-div">
        {
          this.state.checkBalance === true && this.state.address === this.props.match.params.address ?
          <>
          <div className="summary shadow" style={{padding: "12px"}}>
            <div className="row">
              <div className="col-md-1 col-3" style={{"textAlign": "center"}}>
                <QRCode value={this.props.match.params.address} bgColor="#FAFAFA" size="60" />
              </div>
              <div className="col-md-11 col-9">
                <p className="content-table-title" style={{"marginBottom": "5px", "marginTop": "-5px"}}>Address</p>
                <p className="content-table-text" style={{"marginBottom": "5px", "marginTop": "-5px"}}>{this.props.match.params.address} <Tooltip interactive title={`Copy`} placement="top-start"><i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.props.match.params.address)}}></i></Tooltip></p>
                <p className="content-table-text" style={{"marginBottom": "-5px", "marginTop": "-5px"}}>{((parseInt(this.state.balance)+parseInt(this.state.rewards)+parseInt(this.state.delegate)+parseInt(this.state.undelegate))/10**12).toLocaleString(undefined, {maximumFractionDigits: 6})}<b className="title-underline"> {denomination}</b> (<b>$</b>{(parseFloat(this.props.fullData.price)*((parseInt(this.state.balance)+parseInt(this.state.rewards)+parseInt(this.state.delegate)+parseInt(this.state.undelegate))/10**12)).toLocaleString(undefined, {maximumFractionDigits: 3})})</p>
                {
                  (this.state.token).length > 1 ?
                  <Dropdown className="dropdown-earlier">
                    <Dropdown.Toggle className="dropdown-button dropdown-button-token" variant="light">
                      {(this.state.token).length-1} IBC Tokens <i className="bi bi-chevron-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-token overflow-custom summary shadow">
                      {
                        (this.state.token).map(balance => {
                          if(balance.denom !== denomination){
                            return (
                              <Dropdown.Item className="dropdown-item" style={{"textTransform": "uppercase"}}>
                                <Link to={`/ibc/${balance.denomHash}`}>
                                  {((parseInt(balance.amount)/10**12).toLocaleString(undefined, {maximumFractionDigits: 6}))} <b> {balance.denom}</b>
                                  <p className="text-hash" style={{"opacity": "0.75", "fontWeight": "normal", "fontSize": "11px","marginBottom": "0"}}>{balance.denomHash}</p>
                                </Link>
                              </Dropdown.Item>
                            )
                          }else{
                            return (null)
                          }
                        })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                  : null
                }
              </div>
            </div>
          </div>
          <div className="summary shadow" style={{padding: "12px"}}>
            <strong style={{fontSize: "17px", marginLeft: "0"}}>Balance Details</strong>
            <hr/>
            <div className="row">
              <div className="col-md-2" style={{"textAlign": "center"}}>
                <div className="pie-chart">
                  <PieChart
                    data={[
                      { title: 'Available', value: parseInt(this.state.balance)/10**12, color: '#2A9D8F' },
                      { title: 'Delegated', value: parseInt(this.state.delegate)/10**12, color: '#F4A261' },
                      { title: 'Reward', value: parseInt(this.state.rewards)/10**12, color: '#264653' },
                      { title: 'Unbonding', value: parseInt(this.state.undelegate)/10**12, color: '#E76F51' },
                    ]}
                    lineWidth = {30}
                    animate
                  />
                </div>
              </div>
              <div className="col-md-10" style={{"marginTop": "auto"}}>
                <div className="row">
                  <div className="col-md-3">
                    <p className="content-table-title"><i style={{"color": "#2A9D8F"}} className="bi bi-circle-fill"></i> Available</p>
                  </div>
                  <div className="col-md-9">
                    <p className="content-table-text">{(parseInt(this.state.balance)/10**12).toLocaleString(undefined, {maximumFractionDigits: 6})} <b className="title-underline" style={{"textDecorationColor": "#2A9D8F"}}>{denomination}</b> (<b>$</b>{(parseFloat(this.props.fullData.price)*(parseInt(this.state.balance)/10**12)).toLocaleString(undefined, {maximumFractionDigits: 3})})</p>
                  </div>
                  <div className="col-md-3">
                    <p className="content-table-title"><i style={{"color": "#F4A261"}} className="bi bi-circle-fill"></i> Delegated</p>
                  </div>
                  <div className="col-md-9">
                    <p className="content-table-text">{(parseInt(this.state.delegate)/10**12).toLocaleString(undefined, {maximumFractionDigits: 6})} <b className="title-underline" style={{"textDecorationColor": "#F4A261"}}>{denomination}</b> (<b>$</b>{(parseFloat(this.props.fullData.price)*(parseInt(this.state.delegate)/10**12)).toLocaleString(undefined, {maximumFractionDigits: 3})})</p>
                  </div>
                  <div className="col-md-3">
                    <p className="content-table-title"><i style={{"color": "#264653"}} className="bi bi-circle-fill"></i> Reward</p>
                  </div>
                  <div className="col-md-9">
                    <p className="content-table-text">{(parseInt(this.state.rewards)/10**12).toLocaleString(undefined, {maximumFractionDigits: 6})} <b className="title-underline" style={{"textDecorationColor": "#264653"}}>{denomination}</b> (<b>$</b>{(parseFloat(this.props.fullData.price)*(parseInt(this.state.rewards)/10**12)).toLocaleString(undefined, {maximumFractionDigits: 3})})</p>
                  </div>
                  <div className="col-md-3">
                    <p className="content-table-title"><i style={{"color": "#E76F51"}} className="bi bi-circle-fill"></i> Unbonding</p>
                  </div>
                  <div className="col-md-9">
                    <p className="content-table-text">{(parseInt(this.state.undelegate)/10**12).toLocaleString(undefined, {maximumFractionDigits: 6})} <b className="title-underline" style={{"textDecorationColor": "#E76F51"}}>{denomination}</b> (<b>$</b>{(parseFloat(this.props.fullData.price)*(parseInt(this.state.undelegate)/10**12)).toLocaleString(undefined, {maximumFractionDigits: 3})})</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            !this.state.checkDelegations && !this.state.checkUndelegations ?
              <div className="summary shadow" style={{"textAlign": "center", "height": "300px"}}>
                <div style={{margin: "0 auto", marginTop: "125px"}}>
                  <div class="o1"></div>
                  <div class="o2"></div>
                  <div class="o3"></div>
                </div>
              </div>
            :
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="summary shadow" style={{"padding": "12px"}}>
                    <strong style={{fontSize: "17px", marginLeft: "0"}}>Delegated <b style={{"opacity": "0.75", "fontWeight": "normal", "fontSize": "13px"}}>{this.state.delegates ? ((this.state.delegates).length) : 0}</b></strong>
                    <hr/>
                    <div className="overflow-custom" style={{"height": "200px", "overflowX": "hidden","overflowY":"auto",}}>
                      {this.renderDelegate()}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="summary shadow" style={{padding: "12px"}}>
                    <strong style={{fontSize: "17px", marginLeft: "0"}}>Unbonding <b style={{"opacity": "0.75", "fontWeight": "normal", "fontSize": "13px"}}>{this.state.undelegates ? ((this.state.undelegates).length) : 0}</b></strong>
                    <hr/>
                    <div className="overflow-custom" style={{"height": "200px", "overflowX": "hidden","overflowY":"auto",}}>
                      {this.renderUndelegate()}
                    </div>
                  </div>
                </div>
              </div>
              </>
          }
          {
            this.state.checkTxs ?
              <div className="summary shadow overflow-custom" style={{"padding":"12px"}}>
                <strong style={{fontSize: "17px", marginLeft: "0"}}>Transaction</strong>
                <hr/>
                <div className="overflow-custom" style={{"maxHeight": "400px", "overflowY": "auto", "overflowX": "hidden"}}>
                  <TransactionDetail jsonTx={this.state.txs} address={this.props.match.params.address}/>
                </div>
              </div>
            :
            <div className="summary shadow" style={{"textAlign": "center", "height": "300px"}}>
              <div style={{margin: "0 auto", marginTop: "125px"}}>
                <div class="o1"></div>
                <div class="o2"></div>
                <div class="o3"></div>
              </div>
            </div>
          }
          </>
          : 
          <div className="summary shadow" style={{"textAlign": "center", "height": "622px"}}>
            <div style={{margin: "0 auto", marginTop: "275px"}}>
              <div class="o1"></div>
              <div class="o2"></div>
              <div class="o3"></div>
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};

const mapDispatchToProps = {
  getPrice
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);