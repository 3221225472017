import React from 'react';
import { connect } from 'react-redux';
import { getPathName, getIBC } from '../redux/actions/getData';
import { denomination, prefixDefault } from '../data/API';
import {Link} from 'react-router-dom';
import Tooltip from "@material-ui/core/Tooltip";
import moment from 'moment';

class IBC extends React.Component {
  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }
  loopData = (numData) => {
    if((this.props.fullData.latestIBC).length > 0) {
      var totalData = 0;
      var txs = [];
      var colClass1 = "col-md-1 col-5";
      var colClass2 = "col-md-2 col-6";
      var colClass3 = "col-md-2 col-6";
      (this.props.fullData.latestIBC).forEach(tx => {
        if(totalData < numData) {
          if(tx.txAdditional){
            txs.push(
              <Link to={`/tx/${tx.txHash}`} onClick={() => this.changePath("/tx")}>
                <div style={{padding: "6px"}}>
                  <div className="row">
                    <div className="col-md-3 col-7">
                      {
                        tx.status === true ?
                          <p className="text-hash" style={{"textTransform": "capitalize"}}><i className="icon bi-check-circle-fill" style={{"color": "green"}}></i> {tx.txType}</p>
                        :
                        <p className="text-hash" style={{"textTransform": "capitalize"}}><Tooltip interactive title={tx.status} placement="top-start"><i className="icon bi-x-circle-fill" style={{"color": "red"}}></i></Tooltip> {tx.txType}</p>
                      }
                    </div>
                    <div className={colClass1}>
                      {
                        this.props.block ?
                          <p><Link to={`/block/${tx.txHeight}`} onClick={() => this.changePath("/block")}><b>{parseInt(tx.txHeight).toLocaleString()}</b></Link></p>
                        : <p>{moment(tx.txTime).format("D/MM/YY HH:mm:ss")}</p>
                      }
                    </div>
                    <div className={colClass2}>
                      <p className="text-hash"><b>{(parseInt(tx.txAdditional.amount)/10**6).toLocaleString(undefined, {maximumFractionDigits: 3})} </b> 
                      {
                        tx.txAdditional.denom ?
                          tx.txAdditional.denom
                        : denomination
                      }
                      </p>
                    </div>
                    <div className={colClass3}>
                      {
                        this.props.sender ?
                          tx.txSender ?
                            (tx.txSender).includes('valoper') ?
                              <p className="text-hash">From <Link to={`/validator/${tx.txSender}`} onClick={() => this.changePath("/validator")}><b>{tx.txSender}</b></Link></p>
                            : <p className="text-hash">From <Link to={`/address/${tx.txSender}`} onClick={() => this.changePath("/account")}><b>{tx.txSender}</b></Link></p>
                          : null
                        :
                          tx.txAdditional.to ?
                            tx.txAdditional.to && (tx.txAdditional.to).includes(prefixDefault) ?
                              (tx.txAdditional.to).includes('valoper') ?
                                <p className="text-hash">To <Link to={`/validator/${tx.txAdditional.to}`} onClick={() => this.changePath("/validator")}><b>{tx.txAdditional.to}</b></Link></p>
                              : <p className="text-hash">To <Link to={`/address/${tx.txAdditional.to}`} onClick={() => this.changePath("/account")}><b>{tx.txAdditional.to}</b></Link></p>
                            : <p className="text-hash">To <b>{tx.txAdditional.to}</b></p>
                          :
                            tx.txAdditional.to && (tx.txAdditional.to).includes(prefixDefault) ?
                              (tx.txAdditional.to).includes('valoper') ?
                                <p className="text-hash">To <Link to={`/validator/${tx.txAdditional.to}`} onClick={() => this.changePath("/validator")}><b>{tx.txAdditional.to}</b></Link></p>
                              : <p className="text-hash">To <Link to={`/address/${tx.txAdditional.to}`} onClick={() => this.changePath("/account")}><b>{tx.txAdditional.to}</b></Link></p>
                            :
                              tx.txAdditional.to ?
                                <p className="text-hash">To <b>{tx.txAdditional.to}</b></p>
                              : null
                      }
                    </div>
                    <div className={colClass2}>
                      {
                        this.props.channel ?
                          <p className="text-hash">{tx.txAdditional.fromChannel} <i className="bi bi-arrow-right" style={{"color":"blue"}}></i> {tx.txAdditional.toChannel}</p>
                        : <p>Fee <b>{(parseInt(tx.txFee)/10**6).toFixed(5)}</b> {denomination}</p>
                      }
                    </div>
                    {
                      this.props.chainId ?
                      <div className={colClass3}>
                        <p className="text-hash"><b>{tx.txAdditional.chainId}</b></p>
                      </div>
                      :
                        <div className={colClass3}>
                          <p className="text-hash"><b>{tx.txHash}</b></p>
                        </div>
                    }
                  </div>
                </div>
                <hr style={{marginTop: "0px"}} />
              </Link>
            )
          }
        }
        totalData++;
      })
    }
    return txs;
  }
  render() {
    return (
      <div>
        {this.loopData(this.props.total, this.props.grid)}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user,
    pathName: state.user.pathName
  }
};

const mapDispatchToProps = {
  getIBC,
  getPathName,
}

export default connect(mapStateToProps, mapDispatchToProps)(IBC);