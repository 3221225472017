import React from 'react';
import Summary from '../components/Summary';
import Block from '../components/Block';
import Transaction from '../components/Transaction';
import Validator from '../components/Validator';
import { projectName } from '../data/API';

class Home extends React.Component {
  state = {
    blockColumn: "col-md-6",
    blockTotal: 5,
    transactionDisplay: "block",
    blockGrid: true,
    blockArrow: "arrows-fullscreen",
    transactionColumn: "col-md-6",
    transactionTotal: 5,
    blockDisplay: "block",
    transactionGrid: true,
    transactionArrow: "arrows-fullscreen",
    channelValue: "all",
    channelFromText: "All",
    channelToText: "All",
    sender: false,
    block: false,
    gas: false
  }
  componentDidMount(){
    document.title = `${projectName} Scan`;
  }
  render(){
    return(
      <>
        <Summary home />
        <div className="summary-div" style={{marginTop: "10px"}}>
          <div className="row">
            <div className={this.state.blockColumn} style={{display: this.state.blockDisplay}}>
              <div className="summary shadow" style={{borderRadius: "4px", paddingTop: "12px", minHeight: "622px"}}>
                <strong style={{fontSize: "17px", marginLeft: "6px"}}>Latest Block
                {
                  this.state.blockGrid ?
                    <i className={`bi bi-${this.state.blockArrow}`} onClick={() => this.setState({blockColumn: "col-md-12", transactionDisplay: "none", blockGrid: false, blockTotal: 20, blockArrow: "arrows-angle-contract"})} style={{opacity: "0.7", fontSize:"13px", cursor: "pointer", marginLeft: "10px"}}></i>
                  : <i className={`bi bi-${this.state.blockArrow}`} onClick={() => this.setState({blockColumn: "col-md-6", transactionDisplay: "block", blockGrid: true, blockTotal: 5, blockArrow: "arrows-fullscreen"})} style={{opacity: "0.7", fontSize:"13px", cursor: "pointer", marginLeft: "10px"}}></i>
                }
                </strong>
                <hr/>
                <Block total={this.state.blockTotal} grid={this.state.blockGrid} />
              </div>
            </div>
            <div className={this.state.transactionColumn} style={{display: this.state.transactionDisplay}}>
              <div className="summary shadow" style={{borderRadius: "4px", paddingTop: "12px", minHeight: "622px"}}>
                <strong style={{fontSize: "17px", marginLeft: "6px"}}>Latest Transaction
                {
                  this.state.transactionGrid ?
                    <i className={`bi bi-${this.state.transactionArrow}`} onClick={() => this.setState({transactionColumn: "col-md-12", blockDisplay: "none", transactionGrid: false, transactionTotal: 20, transactionArrow: "arrows-angle-contract"})} style={{opacity: "0.7", fontSize:"13px", cursor: "pointer", marginLeft: "10px"}}></i>
                  : <i className={`bi bi-${this.state.transactionArrow}`} onClick={() => this.setState({transactionColumn: "col-md-6", blockDisplay: "block", transactionGrid: true, transactionTotal: 5, transactionArrow: "arrows-fullscreen", sender: false, block: false, gas: false})} style={{opacity: "0.7", fontSize:"13px", cursor: "pointer", marginLeft: "10px"}}></i>
                }
                </strong>
                {
                  !this.state.transactionGrid ? 
                  <>
                    <div className="row" style={{"marginBottom": "10px"}}>
                      <div className="col-md-2 col-4">
                        <label className="switch" for="checkbox">
                          <input className="checkbox" type="checkbox" id="checkbox" checked={this.state.sender} onChange={(e) => this.setState({sender: e.target.checked})}/>
                          <div className="slider round"></div>
                        </label>
                        <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Receiver</p>
                      </div>
                      <div className="col-md-2 col-4">
                        <label className="switch" for="checkbox2">
                          <input className="checkbox" type="checkbox" id="checkbox2" checked={this.state.block} onChange={(e) => this.setState({block: e.target.checked})}/>
                          <div className="slider round"></div>
                        </label>
                        <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Time</p>
                      </div>
                      <div className="col-md-2 col-4">
                        <label className="switch" for="checkbox3">
                          <input className="checkbox" type="checkbox" id="checkbox3" checked={this.state.gas} onChange={(e) => this.setState({gas: e.target.checked})}/>
                          <div className="slider round"></div>
                        </label>
                        <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Gas</p>
                      </div>
                    </div>
                    <hr style={{marginTop: "-10px"}}/>
                  </>
                  : <hr/>
                }
                <Transaction total={this.state.transactionTotal} grid={this.state.transactionGrid} sender={this.state.sender} block={this.state.block} gas={this.state.gas} />
              </div>
            </div>
            <div className="col-md-12" style={{marginTop: "10px"}}>
              <div className="summary shadow" style={{borderRadius: "4px", paddingTop: "12px"}}>
                <strong style={{fontSize: "17px", marginLeft: "6px"}}>Top Validator</strong>
                <hr/>
                <Validator total="10" status="active" pagination={true}/>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Home;