import React from 'react';
import '../assets/css/styles.css';
import { connect } from 'react-redux';
import { apiURL, denomination, projectName, prefixDefault } from '../data/API';
import { getPathName } from '../redux/actions/getData';
import Axios from 'axios';
import Tooltip from "@material-ui/core/Tooltip";
import moment from 'moment';
import {Link} from 'react-router-dom';

class IBC extends React.Component {
  state={
    channel: true,
    block: true,
    chainID: false,
    sender: true
  }
  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }
  componentWillMount(){
    this.getData()
    document.title = `IBC Token ${this.props.match.params.hash} | ${projectName} Scan`;
  }
  getData = async() => {
    Axios.get(`${apiURL}/ibctoken?hash=${this.props.match.params.hash}`)
    .then(response => {
      if(response.data.result){
        this.setState({
          checkDenom: true,
          denom: response.data.data.denom,
          path: response.data.data.path
        })
      }else{
        console.log("Failed to fetch balance")
      }
    })
    .catch(err => {
      console.log("Failed to fetch balance")
    })
    Axios.get(`${apiURL}/ibc?hash=${this.props.match.params.hash}`)
    .then(response => {
      if(response.data.result){
        this.setState({
          checkTxs: true,
          txs: response.data.data,
        })
      }else{
        console.log("Failed to fetch balance")
      }
    })
    .catch(err => {
      console.log("Failed to fetch balance")
    })
  }
  renderTxs = () => {
    if((this.state.txs).length > 0) {
      var txs = [];
      var colClass1 = "col-md-1 col-5";
      var colClass2 = "col-md-2 col-6";
      var colClass3 = "col-md-2 col-6";
      (this.state.txs).forEach(tx => {
        if(tx.txAdditional){
          txs.push(
            <Link to={`/tx/${tx.txHash}`} onClick={() => this.changePath("/tx")}>
              <div>
                <div className="row">
                  <div className="col-md-3 col-7">
                    {
                      tx.status === true ?
                        <p className="text-hash" style={{"textTransform": "capitalize"}}><i className="icon bi-check-circle-fill" style={{"color": "green"}}></i> {tx.txType}</p>
                      :
                      <p className="text-hash" style={{"textTransform": "capitalize"}}><Tooltip interactive title={tx.status} placement="top-start"><i className="icon bi-x-circle-fill" style={{"color": "red"}}></i></Tooltip> {tx.txType}</p>
                    }
                  </div>
                  <div className={colClass1}>
                    {
                      this.state.block ?
                        <p><Link to={`/block/${tx.txHeight}`} className="content-table-title" onClick={() => this.changePath("/block")}>{parseInt(tx.txHeight).toLocaleString()}</Link></p>
                      : <p>{moment(tx.txTime).format("D/MM/YY HH:mm:ss")}</p>
                    }
                  </div>
                  <div className={colClass2}>
                    <p><b>{(parseInt(tx.txAdditional.amount)/10**6).toLocaleString(undefined, {maximumFractionDigits: 3})}</b> {
                      tx.txAdditional.denom ?
                        tx.txAdditional.denom
                      : denomination
                    }</p>
                  </div>
                  <div className={colClass3}>
                    {
                      this.state.sender ?
                        tx.txSender ?
                          (tx.txSender).includes('valoper') ?
                            <p className="text-hash">From <Link to={`/validator/${tx.txSender}`} onClick={() => this.changePath("/validator")}><b>{tx.txSender}</b></Link></p>
                          : <p className="text-hash">From <Link to={`/address/${tx.txSender}`} onClick={() => this.changePath("/account")}><b>{tx.txSender}</b></Link></p>
                        : null
                      :
                        tx.txAdditional.to ?
                          tx.txAdditional.to && (tx.txAdditional.to).includes(prefixDefault) ?
                            (tx.txAdditional.to).includes('valoper') ?
                              <p className="text-hash">To <Link to={`/validator/${tx.txAdditional.to}`} onClick={() => this.changePath("/validator")}><b>{tx.txAdditional.to}</b></Link></p>
                            : <p className="text-hash">To <Link to={`/address/${tx.txAdditional.to}`} onClick={() => this.changePath("/account")}><b>{tx.txAdditional.to}</b></Link></p>
                          : <p className="text-hash">To <b>{tx.txAdditional.to}</b></p>
                        :
                          tx.txAdditional.to && (tx.txAdditional.to).includes(prefixDefault) ?
                            (tx.txAdditional.to).includes('valoper') ?
                              <p className="text-hash">To <Link to={`/validator/${tx.txAdditional.to}`} onClick={() => this.changePath("/validator")}><b>{tx.txAdditional.to}</b></Link></p>
                            : <p className="text-hash">To <Link to={`/address/${tx.txAdditional.to}`} onClick={() => this.changePath("/account")}><b>{tx.txAdditional.to}</b></Link></p>
                          :
                            tx.txAdditional.to ?
                              <p className="text-hash">To <b>{tx.txAdditional.to}</b></p>
                            : null
                    }
                  </div>
                  <div className={colClass2}>
                    {
                      this.state.channel ?
                        <p className="text-hash">{tx.txAdditional.fromChannel} <i className="bi bi-arrow-right" style={{"color":"blue"}}></i> {tx.txAdditional.toChannel}</p>
                      : <p>Fee <b>{(parseInt(tx.txFee)/10**6).toFixed(5)}</b> {denomination}</p>
                    }
                  </div>
                  {
                    this.state.chainID ?
                    <div className={colClass3}>
                      <p className="text-hash"><b className="content-table-title">{tx.txAdditional.chainId}</b></p>
                    </div>
                    :
                      <div className={colClass3}>
                        <p className="text-hash"><b className="content-table-title">{tx.txHash}</b></p>
                      </div>
                  }
                </div>
                <hr style={{marginTop: "5px"}} />
              </div>
            </Link>
          )
        }
      })
    }
    return txs;
  }
  render(){
    return(
      <div className="summary-div">
        {
          this.state.checkDenom && this.state.checkTxs ?
          <>
            <div className="summary shadow" style={{padding: "12px"}}>
              <strong style={{fontSize: "17px", marginLeft: "0"}}>IBC Token Details</strong>
              <hr/>
              <div className="row">
                <div className="col-md-1">
                  <p className="content-table-title">Hash</p>
                </div>
                <div className="col-md-11">
                  <p className="content-table-text"> {this.props.match.params.hash} <Tooltip interactive title={`Copy`} placement="top-start"><i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.props.match.params.hash)}}></i></Tooltip></p>
                </div>
                <div className="col-md-1">
                  <p className="content-table-title">Base</p>
                </div>
                <div className="col-md-11">
                  <p className="content-table-text">{this.state.denom}</p>
                </div>
                <div className="col-md-1">
                  <p className="content-table-title">Channel</p>
                </div>
                <div className="col-md-11">
                  <p className="content-table-text" style={{textTransform: "capitalize"}}>{((this.state.path).split("/")[1]).replace("-", " ")}</p>
                </div>
              </div>
            </div>
            <div className="summary shadow" style={{padding: "12px"}}>
              <strong style={{fontSize: "17px", marginLeft: "0"}}>Latest Transactions</strong>
              <div className="row" style={{"marginBottom": "10px"}}>
                <div className="col-md-2 col-4">
                  <label className="switch" for="checkbox">
                    <input className="checkbox" type="checkbox" id="checkbox" checked={this.state.chainId} onChange={(e) => this.setState({chainID: e.target.checked})}/>
                    <div className="slider round"></div>
                  </label>
                  <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Chain ID</p>
                </div>
                <div className="col-md-2 col-4">
                  <label className="switch" for="checkbox2">
                    <input className="checkbox" type="checkbox" id="checkbox2" checked={this.state.sender} onChange={(e) => this.setState({sender: e.target.checked})}/>
                    <div className="slider round"></div>
                  </label>
                  <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Sender</p>
                </div>
                <div className="col-md-2 col-4">
                  <label className="switch" for="checkbox3">
                    <input className="checkbox" type="checkbox" id="checkbox3" checked={this.state.channel} onChange={(e) => this.setState({channel: e.target.checked})}/>
                    <div className="slider round"></div>
                  </label>
                  <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Channel</p>
                </div>
                <div className="col-md-2 col-4">
                  <label className="switch" for="checkbox4">
                    <input className="checkbox" type="checkbox" id="checkbox4" checked={this.state.block} onChange={(e) => this.setState({block: e.target.checked})}/>
                    <div className="slider round"></div>
                  </label>
                  <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Block</p>
                </div>
              </div>
              <hr style={{marginTop: "-10px"}} />
              <div className="overflow-custom" style={{"maxHeight": "400px", "overflowY": "auto", "overflowX": "hidden"}}>
                {this.renderTxs()}
              </div>
            </div>
          </>
          :
          <div className="summary shadow" style={{"textAlign": "center", height: "622px"}}>
            <div style={{margin: "0 auto", marginTop: "275px"}}>
              <div class="o1"></div>
              <div class="o2"></div>
              <div class="o3"></div>
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pathName: state.user.pathName
  }
};

const mapDispatchToProps = {
  getPathName,
}

export default connect(mapStateToProps, mapDispatchToProps)(IBC);